import { useState, useRef, useEffect } from 'react'
import parse from 'html-react-parser'
import va from '@vercel/analytics'

import LeftArrow from '@components/Icons/LeftArrow.jsx'
import RightArrow from '@components/Icons/RightArrow.jsx'
import StarSolid from '@components/Icons/StarSolid.jsx'
import StarHalf from '@components/Icons/StarHalf.jsx'

const BestSellingCarousel = ({
    carouselData,
    aspect = '2/3',
    carouselType,
    productData,
}) => {
    const data = carouselData
    const [products, setProducts] = useState(data?.items || [])

    const maxScrollWidth = useRef(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const carousel = useRef(null)
    const totalSlides = products?.length
    const [scrollX, setScrollX] = useState(0)
    const [scrollEnd, setScrollEnd] = useState(false)

    let tileWidthClass = ''

    const movePrev = () => {
        if (carousel.current !== null) {
            let child
            let frameWidth = carousel.current.firstChild.offsetWidth

            let scrollFrame =
                Math.ceil(carousel.current.offsetWidth / frameWidth) - 1
            if (window.innerWidth > 800) {
                carousel.current.scrollLeft -= frameWidth * 3 + 10
            } else {
                carousel.current.scrollLeft -= frameWidth * 2 + 10
            }
            // setCurrentIndex((prevState) => prevState + 1)
        }
    }

    const moveNext = () => {
        if (carousel.current !== null) {
            let frameWidth = carousel.current.firstChild.offsetWidth

            let scrollFrame =
                Math.ceil(carousel.current.offsetWidth / frameWidth) - 1

            if (window.innerWidth > 800) {
                carousel.current.scrollLeft += frameWidth * 3 + 10
            } else {
                carousel.current.scrollLeft += frameWidth * 2 + 10
            }
        }
    }

    useEffect(() => {
        maxScrollWidth.current = carousel.current
            ? carousel.current.scrollWidth - carousel.current.offsetWidth
            : 0
    }, [])

    const scrollFunction = (e) => {
        setScrollX(carousel.current.scrollLeft)
        if (
            Math.floor(
                carousel.current.scrollWidth - carousel.current.scrollLeft
            ) <= carousel.current.offsetWidth
        ) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
    }

    //

    useEffect(() => {
        const loadBV = async () => {
            const scriptTag = document.createElement('script')
            scriptTag.async = true
            scriptTag.defer = true
            scriptTag.src =
                'https://apps.bazaarvoice.com/deployments/monicaandandy/main_site/production/en_US/bv.js'
            document.body.appendChild(scriptTag)
        }

        setTimeout(() => {
            loadBV()
            setTimeout(() => {
                const stars = document.querySelectorAll(
                    '.bv_stars_svg_no_wrap > svg'
                )
                stars.forEach((star) => {
                    star.style.width = '16px'
                    star.style.height = '16px'
                })
            }, 2000)
        }, 3000)
    }, [])

    return (
        <div className="carousel-outer max-w-[90vw] 2xl:max-w-[1400px] mx-auto">
            <div className="carousel-inner wrap-[90vw] overflow-x-hidden lg:wrap-xl mx-auto">
                <div className="carousel mx-auto wrap-[90vw] overflow-x-hidden lg:wrap-xl relative">
                    <div className="carousel-header relative flex flex-col lg:flex-row items-center mb-3 justify-center">
                        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-center uppercase">
                            {data && data.title ? data.title : 'Best Sellers'}
                        </h2>
                        {/* <div className="carousel-header-wrap justify-center lg:justify-end lg:ml-auto flex flex-col lg:flex-row items-center gap-3">
                            <div className="shop-all-links flex items-center gap-3">
                                <div className="shop-all-link">
                                    {data && data.linkText ? (
                                        <a
                                            href={
                                                data && data.link
                                                    ? data.link
                                                    : '/collections/baby-clothes'
                                            }
                                            className="text-fl-sm underline lg:mr-3"
                                        >
                                            {data && data.linkText
                                                ? data.linkText
                                                : ''}
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="relative overflow-x-hidden">
                        <div
                            className={`angle-left flex items-center justify-center bg-white h-12 w-12 p-3 rounded-full absolute left-[2%] z-50 top-1/3 opacity-90 hover:opacity-100 ${
                                scrollX === 0
                                    ? 'cursor-not-allowed !opacity-50'
                                    : 'cursor-pointer'
                            }`}
                            onClick={() => movePrev()}
                            disabled={scrollX === 0}
                        >
                            <LeftArrow width="w-[32px]" />
                        </div>
                        <div
                            className={`angle-right flex items-center justify-center bg-white h-12 w-12 p-3 rounded-full absolute right-[2%] z-50 top-1/3 opacity-90 hover:opacity-100 ${
                                scrollEnd
                                    ? 'cursor-not-allowed !opacity-50'
                                    : 'cursor-pointer'
                            }`}
                            onClick={() => moveNext()}
                            disabled={scrollEnd}
                        >
                            <RightArrow width="w-[32px]" />
                        </div>

                        <div
                            onScroll={(e) => scrollFunction(e)}
                            ref={carousel}
                            className="carousel-container relative grid grid-flow-col auto-cols-[minmax(200px,1fr)] lg:auto-cols-[minmax(240px,1fr)] gap-1 overflow-x-scroll scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
                        >
                            {products &&
                                products.map((item, index) => {
                                    let imageUrl = item?.imageUrl
                                    let handle = item?.slug
                                    let description = parse(item?.description)

                                    return (
                                        <div
                                            key={index}
                                            className={`carousel-item relative h-auto product-item aspect-[2/3] flex flex-col items-center justify-center text-center`}
                                        >
                                            <div className="product-image">
                                                <a
                                                    href={handle}
                                                    className={`
                                                     h-auto product-item aspect-[2/3] object-cover
                                                     block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 relative hover:opacity-80`}
                                                    style={{
                                                        backgroundImage: `url(${
                                                            imageUrl || ''
                                                        })`,
                                                    }}
                                                    onClick={() => {
                                                        va.track(
                                                            'Best Selling Carousel Tile Click',
                                                            {
                                                                itemType:
                                                                    item?.type,
                                                                itemName:
                                                                    item?.title,
                                                                itemLink:
                                                                    handle,
                                                            }
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        loading="lazy"
                                                        src={imageUrl || ''}
                                                        alt={item?.title}
                                                        className={`w-full product-item aspect-[2/3] object-cover
                                                    `}
                                                    />
                                                    <span className="sr-only">
                                                        {item?.title
                                                            ? item?.title
                                                            : ''}
                                                    </span>
                                                    <span className="sr-only">
                                                        {item.itemName}
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="product-details flex flex-col items-center justify-center text-center">
                                                <h3
                                                    className={`mx-auto mt-2 text-base !leading-[1.3] text-sm`}
                                                >
                                                    <a
                                                        className="hover:underline"
                                                        href={handle}
                                                    >
                                                        {item.title.replace(
                                                            '_',
                                                            ' - '
                                                        )}
                                                    </a>
                                                </h3>
                                                <p className="text-xs text-ash my-1 font-normal">
                                                    {description}
                                                </p>
                                                {item.id ? (
                                                    <div
                                                        className="bv-inline-rating mx-auto"
                                                        data-bv-show="inline_rating"
                                                        data-bv-product-id={
                                                            item.id
                                                        }
                                                        data-bv-seo="false"
                                                    ></div>
                                                ) : null}
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BestSellingCarousel
